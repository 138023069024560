@import '~@elastic/charts/dist/theme_only_light.css';

#select-patient-page {
}

#patient-page {
  .euiTitle {
    margin-bottom: 0;
  }
}

.chart-panel {
  text-align: center;
}
