@import "variables";
@import "scss/main";

@font-face {
    font-family: 'Noto Sans Bold';
    src: local('Noto Sans Bold'), url(./assets/fonts/NotoSans-Bold.ttf) format('ttf');
}

@font-face {
    font-family: 'Noto Sans';
    src: local('Noto Sans Regular'), url(./assets/fonts/NotoSans-Regular.ttf) format('tff');
}

@import '~@elastic/eui/dist/eui_theme_light.css';
@import "components/index";
@import '~d3-v6-tip/src/d3-tip.css';

body  {
    font-family: 'Noto Sans', sans-serif !important;
}

.euiButton--primary.euiButton--fill:not([class*='isDisabled']){
    background-color: $accent !important;
    border-color: $accent !important;
    color: white;
}

.euiButton-isDisabled {
    cursor: not-allowed !important;
}

.euiButtonEmpty--primary {
    color: $accent !important;
    border-bottom: 1px solid $accent;
}

.euiHeader {
    background: #fafbfd;
    border-bottom: none;
}

.d3-tip {
    max-width: 250px;
    font-size: 13px;
}
