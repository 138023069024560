.euiHeaderLogo__text {
    color: $primary;
}

.euiHeaderProfile {
    .euiButtonEmpty--primary {
        border: none !important;
    }
}

a {
    color: black;
}